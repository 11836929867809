import '@/services/datadog'

import type { AppContext, AppInitialProps, AppProps } from 'next/app'
import App from 'next/app'
import dynamic from 'next/dynamic'
import type { Session } from 'next-auth'
import { appWithTranslation } from 'next-i18next'

import env from '@beam-australia/react-env'
import type { AnalyticsConfig } from '@knauf-group/cst-analytics'
import { AnalyticsProvider } from '@knauf-group/cst-analytics'
import globalTheme, {
  globalThemeAlternativeFont,
} from '@knauf-group/ct-designs/themeConfigs/rebranding/theme'
import { isUnsupportedKnaufFont } from '@knauf-group/ct-designs/utils/utils'
import SessionProvider from '@knauf-group/ct-shared-nextjs/nextauth/react'
import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { AppCacheProvider } from '@mui/material-nextjs/v14-pagesRouter'
import type { IToggle } from '@unleash/nextjs'
import { FlagProvider, getFrontendFlags } from '@unleash/nextjs/client'
import { SnackbarProvider } from 'notistack'
import { getEmotionCache } from 'src/utils/getEmotionCache'
import { UAParser } from 'ua-parser-js'

import { ASSET_PREFIX } from '@/constants'
import { StoreProvider } from '@/contexts/StoreProvider'

import nextI18NextConfig from '../../next-i18next.config'
import { getPageDirection } from '../utils/getPageDirection'
import { isRTLLocale } from '../utils/isRTLLocale'
import { createGlobalThemeBasedOnDevice } from '../utils/themeBasedOnDevice'

type AppOwnProps = {
  deviceType: string
  // Unleash
  toggles: IToggle[]
}

type PageProps = {
  // auth
  session: Session
  now: number
}

type DCAppProps = AppProps<PageProps> & AppOwnProps

export const isStaticErrorPage = (pathname: string) =>
  ['/404', '/500', '/auth/[slug]'].includes(pathname)

const DynamicKnaufFont = dynamic(() => import('@/components/KnaufFont'))

const analyticsConfig: AnalyticsConfig = {
  appName: 'knauf_download_center',
  adobeToken: env('ADOBE_LAUNCH_SCRIPT_URL'),
}

const DCApp = (props: DCAppProps) => {
  const { Component, pageProps, router, deviceType, toggles } = props
  const { locale } = router

  const isRTL = isRTLLocale(locale!)
  const direction = getPageDirection(isRTL)

  const isAlternativeFont = isUnsupportedKnaufFont(locale!)
  const theme = isAlternativeFont ? globalThemeAlternativeFont : globalTheme

  const content = (
    <AppCacheProvider {...props} emotionCache={getEmotionCache(isRTL)}>
      <ThemeProvider theme={createGlobalThemeBasedOnDevice(deviceType, theme, direction)}>
        {!isAlternativeFont && <DynamicKnaufFont />}
        <CssBaseline />
        <SessionProvider
          session={pageProps.session}
          basePath={`/${ASSET_PREFIX}/api/auth`}
          showLoadingSessionSpinner={false}
        >
          <SnackbarProvider maxSnack={3}>
            <StoreProvider>
              <AnalyticsProvider config={analyticsConfig}>
                <Component {...pageProps} />
              </AnalyticsProvider>
            </StoreProvider>
          </SnackbarProvider>
        </SessionProvider>
      </ThemeProvider>
    </AppCacheProvider>
  )

  // if the page is a staticly-generated page, don't use `UnleashProvider`
  // because we cannot pass environment variables to `UnleashProvider` dynamically
  // in staticly-generated pages. For now the only staticly-generated pages are
  // '/404', '/auth/[slug]', and '/500' pages
  if (isStaticErrorPage(router.pathname)) {
    return content
  }

  return (
    <FlagProvider
      config={{
        url: env('UNLEASH_FRONTEND_API_URL'),
        clientKey: env('UNLEASH_FRONTEND_API_TOKEN'),
        appName: env('UNLEASH_APP_NAME'),
        bootstrap: toggles,
        disableRefresh: true,
        disableMetrics: true,
      }}
    >
      {content}
    </FlagProvider>
  )
}

DCApp.getInitialProps = async (context: AppContext): Promise<AppInitialProps & AppOwnProps> => {
  let deviceType

  if (context.ctx.req) {
    const userAgent = context.ctx.req.headers['user-agent']
    deviceType = new UAParser(userAgent).getDevice().type
  }

  const { toggles } = isStaticErrorPage(context.router.pathname)
    ? { toggles: [] }
    : await getFrontendFlags()

  return {
    ...(await App.getInitialProps(context)),
    deviceType: deviceType ?? 'desktop',
    toggles,
  }
}

export default appWithTranslation(DCApp, nextI18NextConfig)
