import env from '@beam-australia/react-env'

export const APP_NAME = 'download-center'
export const ASSET_PREFIX = 'dlc-fe'
export const IMAGE_PREFIX = 'images'

export const ALGOLIA_INDEX_PREFIX = env('ALGOLIA_INDEX_PREFIX')

export const DOWNLOAD_CENTER_SERVICE_URL = env('DOWNLOAD_CENTER_SERVICE_URL')

export const MUI_PRO_LICENSE_KEY =
  'eefd29e6da9ad475193fce40b1b0abdaTz03OTY0NCxFPTE3MzI4ODgxNTYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='

export const STORE_AVAILABILITY_TITLE = 'Im Baumarkt erhältlich'

export const ANALYTICS_STRING_SEPARATOR = ';'

export const DOWNLOAD_BUTTON_DATACY = 'download-button'
export const CART_DOWNLOAD_BUTTON_DATACY = 'cart-download-button'
export const SINGLE_DOCUMENT_DOWNLOAD_BUTTON_DATACY =
  'DownloadDocument-TableItem-DownloadButton'
