import { keys, map, values } from 'lodash'

import type { SelectedAsset } from '@/types'

import type {
  CartState,
  ClearAllSelectedAssetsAction,
  ClearSelectedAssetAction,
  CloseCartAction,
  OpenCartAction,
  ToggleSelectedAssetAction,
} from './types'
import { ActionType } from './types'

export const openCart = (): OpenCartAction => {
  return {
    type: ActionType.OpenCart,
  }
}

export const closeCart = (): CloseCartAction => {
  return {
    type: ActionType.CloseCart,
  }
}

export const toggleSelectedAsset = (asset: SelectedAsset): ToggleSelectedAssetAction => {
  return {
    type: ActionType.ToggleSelectedAsset,
    payload: asset,
  }
}

export const clearSelectedAsset = (assetId: string): ClearSelectedAssetAction => {
  return {
    type: ActionType.ClearSelectedAsset,
    payload: assetId,
  }
}

export const clearAllSelectedAssets = (): ClearAllSelectedAssetsAction => {
  return {
    type: ActionType.ClearAllSelectedAssets,
  }
}

export const getSelectedAssetIds = (state: CartState): string[] => {
  return keys(state.selectedAssets)
}

export const getSelectedAssetNames = (state: CartState): string[] => {
  return map(state.selectedAssets, 'name')
}

export const getSelectedAssetLanguages = (state: CartState): string[] => {
  return map(state.selectedAssets, 'language')
}

export const getSelectedAssets = (state: CartState): SelectedAsset[] => {
  return values(state.selectedAssets)
}
